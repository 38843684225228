import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/shared.css";
import VueI18n from "vue-i18n";
import VueGtag from "vue-gtag";

Vue.use(VueI18n);

import { languages } from "./index.js";
import { defaultLocale } from "./index.js";
const messages = Object.assign(languages);

Vue.config.productionTip = false;

Vue.use(
	VueGtag,
	{
		config: { id: "G-WJ4ESGJL5W" },
	},
	router
);

// 言語の設定
Vue.use(VueI18n);
const i18n = new VueI18n({
	locale: defaultLocale, // デフォルト言語はjaにしておくが、ブラウザの言語を拾ってきてここに入れる => 言語変更されたら書き換える
	fallbackLocale: "ja",
	messages: messages,
});

new Vue({
	router,
	i18n,
	render: function (h) {
		return h(App);
	},
}).$mount("#app");
