import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "front",
		component: function () {
			return import(/* webpackChunkName: "about" */ "../views/FrontView.vue");
		},
	},
	{
		path: "/community",
		name: "community",
		component: function () {
			return import(/* webpackChunkName: "about" */ "../views/ConnectView.vue");
		},
	},
	{
		path: "/galleryayame",
		name: "galleryayame",
		component: function () {
			return import(
				/* webpackChunkName: "about" */ "../views/GalleryAyame.vue"
			);
		},
	},
	{
		path: "/galleryhime",
		name: "galleryhime",
		component: function () {
			return import(/* webpackChunkName: "about" */ "../views/GalleryHime.vue");
		},
	},
	{
		path: "/galleryspecial",
		name: "galleryspecial",
		component: function () {
			return import(
				/* webpackChunkName: "about" */ "../views/GallerySpecial.vue"
			);
		},
	},
	{
		path: "/gallery",
		name: "gallery",
		component: function () {
			return import(/* webpackChunkName: "about" */ "../views/GalleryView.vue");
		},
	},
	{
		path: "/gettingready",
		name: "gettingready",
		component: function () {
			return import(
				/* webpackChunkName: "about" */ "../views/GettingReady.vue"
			);
		},
	},
	{
		path: "/license",
		name: "license",
		component: function () {
			return import(/* webpackChunkName: "about" */ "../views/LicenseView.vue");
		},
	},
	{
		path: "/realworld",
		name: "realworld",
		component: function () {
			return import(
				/* webpackChunkName: "about" */ "../views/RealworldView.vue"
			);
		},
	},
	{
		path: "/team",
		name: "team",
		component: function () {
			return import(/* webpackChunkName: "about" */ "../views/TeamView.vue");
		},
	},
	{
		path: "/vision",
		name: "vision",
		component: function () {
			return import(/* webpackChunkName: "about" */ "../views/VisionView.vue");
		},
	},
	{
		path: "/Thanks1",
		name: "Thanks1",
		component: function () {
			return import(/* webpackChunkName: "about" */ "../views/Thanks1.vue");
		},
	},
	{
		path: "/Sample",
		name: "Sample",
		component: function () {
			return import(/* webpackChunkName: "about" */ "../views/Sample.vue");
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;