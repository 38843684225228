<template>
<!-- OGP設定追加 -->
  <!-- <head>
    <meta property="og:url" content="https://bloomiris.net">
    <meta property="og:type" content="website">
    <meta property="og:image" content="https://fancy-usa-2245.lomo.jp/bloomiris.net/dist/og_front.png">
    <meta property="og:title" content="Bloomiris">
    <meta name="twitter:card" content="Bloomirisはデジタルアートを中心に展開するデジタルブランドです">
  </head> -->
  <!-- ここまで -->
  <body id="app">
    <router-view />
  </body>
</template>npm install

<script>
export default {
  components: {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
